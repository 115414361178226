import * as React from "react"
import { getSrc } from "gatsby-plugin-image"

import Breadcrumb from "../components/breadcrumb"
import Constrain from "../components/constrain"
import Paragraph from "../components/paragraph"
import Layout from "../layouts/default"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Small from "../components/small"
import Stack from "../components/stack"
import VideoPlayer from "../components/video-player"

function VideoView({ video }) {
  return (
    <Layout backdrop="videos">
      <Seo
        title={video.name}
        description={video.description}
        image={getSrc(video.meta.cover)}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Turm-TV",
              link: "/turm-tv",
            },
            {
              title: "Expert*innen-Videos",
              link: "/videos",
            },
            {
              title: video.name,
              link: video.nameSlug,
            },
          ]}
        />
        <PageTitle>{video.name}</PageTitle>
        <VideoPlayer src={video.meta.src} poster={video.meta.cover} />
        <Constrain>
          <Stack>
            <Paragraph>{video.description}</Paragraph>
            {video.secondary && <Small>{video.secondary}</Small>}
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default VideoView
